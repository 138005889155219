<template>
  <div class="text-left">
              <div class="w-full flex text-c14 font-c4 text-left">
                <div class="w-3/4 flex items-center">
                  <icon
                      :data="icons.caisse"
                      height="35"
                      width="35"
                      class="mr-2 cursor-pointer"
                      original
                  />
                  <div class="text-c16 w-full">
                    <span>{{ item.person.fullname }}</span>
                  </div>
                </div>
                <div class="w-1/4 text-c12 text-60">
  <!--                <span class="text-green-clear font-c7">+2% </span>cette semaine-->
                </div>
              </div>

              <div class="mt-4 flex">
                <div class="w-1/2">
                  <div class="text-c8 text-60">Solde espèce</div>
                  <div class="text-black text-c14 font-c5 mt-1">
                    <span v-if="item.stats">
                      {{ refactorAmount(item.stats.cashAmount, '.') }}
                    </span>
                    <span v-if="!item.stats">
                      {{ refactorAmount(item.amount, '.') }}
                    </span>
                    <span class="text-90"> fcfa</span>
                  </div>
                </div>

                <div class="w-1/2">
                  <div class="text-c8 text-60">Solde Mobile Money</div>
                  <div class="text-black text-c14 font-c5 mt-1">
                    <span v-if="item.stats">
                      {{ refactorAmount(item.stats.mobileMoneyAmount, '.') }}
                    </span>
                    <span v-if="!item.stats">
                      {{ refactorAmount(item.amount, '.') }}
                    </span>
                    <span class="text-90"> fcfa</span>
                  </div>
                </div>
              </div>

            <div class="mt-2 flex">
              <div class="w-1/2">
                <div class="text-c8 text-60">Solde chèque</div>
                <div class="text-black text-c14 font-c5 mt-1">
                  <span v-if="item.stats">
                    {{ refactorAmount(item.stats.checkAmount, '.') }}
                  </span>
                  <span v-if="!item.stats">
                    {{ refactorAmount(item.amount, '.') }}
                  </span>
                  <span class="text-90"> fcfa</span>
                </div>
              </div>

              <div class="w-1/2"/>
            </div>

            <div class="flex mt-4 items-center">
              <div class="w-1/2">
                <div class="text-c8 text-60">Responsable actuel :</div>
                <div class="flex items-center mt-2">
                  <namer
                      :first="item.person.fullname"
                      :second="item.person.fullname.substr(1, 1)"
                      height="23px"
                      width="23px"
                      size="9px"
                  />
                  <div class="ml-2 text-c10">{{ item.person.fullname }}</div>
                </div>
              </div>

              <boutton label="Contrôle de caisse" size="12px" height="32px" radius="15.7863px" background="#5138EE" class="w-1/2" @info="gotoControle"/>
            </div>

  </div>
</template>

<script>
import caisse from '../../../assets/icons/caisseGreen.svg'
import namer from '../../helper/add/nameLabel'
import boutton from  '../../helper/add/button'

export default {
  name: "Index",
  components: {
    namer,
    boutton
  },
  props: {
    item: {
      type: Array,
      default: null
    },
    user: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      icons: {
        caisse,
      },
      donne: null,
    }
  },

  created () {
  },

  methods: {

    directrice (item) {
      if (item.person.profession === 'Directrice') {
        if (item.person.user.id === this.user.id) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    },

    refactorAmount (a, b) {
      a = '' + a;
      b = b || ' ';
      let c = '',
          d = 0;
      while (a.match(/^0[0-9]/)) {
        a = a.substr(1);
      }
      for (let i = a.length-1; i >= 0; i--) {
        c = (d !== 0 && d % 3 === 0) ? a[i] + b + c : a[i] + c;
        d++;
      }
      return c;
    },
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.item{
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
</style>
