<template>
  <div class="text-left">
    <div>
      <div class="text-90 text-c18">Caisses Comptables</div>
      <div class="h-1 bg-d w-full"> </div>
    </div>

    <div class="flex flex-wrap">
        <div class="w-1/3 pr-4" v-for="(item, index) in donne" :key="index">
          <div
              class="item bg-white cursor-pointer p-6 mt-6"
              v-if="item.person.profession !== 'Directeur' && item.person.profession !== 'Directrice'"
              @click="goToDetail(item)"
          >
            <liste :item="item"/>
          </div>
        </div>
      </div>


    <div class="mt-12">
      <div class="text-90 text-c18">Caisses directions</div>
      <div class="h-1 bg-d w-full"> </div>
    </div>

    <div class="flex flex-wrap">
      <div class="w-1/3 pr-4" v-for="(item, index) in donne" :key="index">
        <div
            class="item bg-white cursor-pointer p-6 mt-6"
            v-if="item.person.profession === 'Directeur' || item.person.profession === 'Directrice' && directrice(item)"
            @click="goToDetail(item)"
        >
          <liste :item="item"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import caisse from '../../../assets/icons/caisseGreen.svg'
import liste from './listAssociate'

export default {
  name: "Index",
  components: {
    liste
  },
  props: {
    donnes: {
      type: Array,
      default: null
    },
    user: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      icons: {
        caisse,
      },
      donne: null,
    }
  },

  created () {
    this.donne = this.donnes
    for (let item in this.donne) {
      if (this.donne[item].id === '624b1a99-d95b-4fd2-bd1a-683643b69573') {
        this.donne.splice(item, 1)
      }
    }
  },

  methods: {
    goToDetail (item) {
      item.genre = 'LINKED'
      this.$store.dispatch('saveTemporalItem', item)
      this.$router.push({path: '/caisseDetail', query: {y: item.id}})
    },

    directrice (item) {
      if (item.person.profession === 'Directrice') {
        if (item.person.user.id === this.user.id) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    },

    refactorAmount (a, b) {
      a = '' + a;
      b = b || ' ';
      let c = '',
          d = 0;
      while (a.match(/^0[0-9]/)) {
        a = a.substr(1);
      }
      for (let i = a.length-1; i >= 0; i--) {
        c = (d !== 0 && d % 3 === 0) ? a[i] + b + c : a[i] + c;
        d++;
      }
      return c;
    },
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.item{
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
</style>
