<template>
  <popupCharge :activation="charge" v-if="charge"/>
  <div class="p-10 pr-16 all">
    <div class="pt-20">
      <div
        class="flex items-center mt-24"
      >
        <div class="w-full flex text-left text-c36 items-center font-c6">
          <div class="ml-4">
            Caisses
          </div>
        </div>
      </div>

      <div class="mt-6 pl-4" v-if="allData !== null">
        <bloc :donnes="allData.agencies" />

        <div class="mt-12">
          <lie :donnes="allData.persons" :user="user"/>
        </div>

        <div class="mt-12">
          <bloc :donnes="allData.departments" type="Caisses par département" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import back from '../../assets/icons/backBut.svg'
import bloc from '../../component/cdevs/caisse/defaultBloc'
import lie from '../../component/cdevs/caisse/associateBloc'
import apiroutes from '../../router/api-routes'
import http from '../../plugins/https'
import popupCharge from '../../component/popup/popupCharge'

export default {
  name: "Index",

  components: {
    bloc,
    lie,
    popupCharge
  },

  data () {
    return {
      icons: {
        back,
      },
      allData: null,
      charge: true,
      user: null
    }
  },

  created () {
    this.user = this.parseJwt(this.$store.getters.token)
    this.allWallet()
  },

  methods: {
    allWallet () {
      console.log(apiroutes.wallet)
      http.get(apiroutes.baseURL + apiroutes.wallet)
          .then(response => {
            console.log('all wallet')
            console.log(response)

            this.accessController(response.data)
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    accessController (obj) {
        // if (this.user.type === 'ACCOUNTING') {
        //   if (this.user.id !== '5233d7e7-efd0-45b7-9ca5-6b022d65025c') {
        //     const data = obj.departments
        //
        //     for (let i in data) {
        //       if (data[i].department === 'ACCOUNTING') {
        //         obj.departments.splice(i, 1)
        //       }
        //     }
        //   }
        // }
      this.allData = obj
    },

    parseJwt (token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''));
      return JSON.parse(jsonPayload)
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: transparent;
}
.all{
  min-height: 100vh;
}
</style>
